import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const KUTVNews = (props) => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "kutv-news.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        alt="KUTV News"
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        className={props.className}
        style={{ width: "220px" }}
      />
    )}
  />
);
export default KUTVNews;
